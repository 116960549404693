
@import url('https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&family=Poppins:wght@400;500;700&display=swap');

:root {
  /* Colors */
  --blackpure: #000;
  --black: #171718;
  --black-border: #26292d;
  --white: #fff;
  --purple: #5c62ec;

  /* Nav */
  --nav-bg: var(--black);
  --nav-border: var(--black-border);
  --nav-text: var(--white);

  /* Header */
  --header-bg: var(--black);
  --header-text: var(--white);

  /* Footer */
  --footer-bg: var(--black);
  --footer-text: var(--white);

  /* Light mode */
  --page-bg: var(--white);
  --text-color: var(--black);
  --accent: var(--purple);
  --title-1: var(--accent);

  --project-card-bg: var(--white);
  --project-card-text: var(--black);
  --box-shadow: 0px 5px 35px rgba(0, 0, 0, 0.25);
}

.dark {
  /* Dark mode */
  --page-bg: #252526;
  --text-color: var(--white);

  --title-1: var(--white);

  --project-card-bg: var(--black);
  --project-card-text: var(--white);
  --box-shadow: 0px 5px 35px rgba(0, 0, 0, 0.8);
}

html,
body {
  margin: auto;
  font-size: 20px;
  font-family: "Droid Sans", arial;
  src: url(./fonts/droid-sans.ttf);
  /* Light mode */
  background-color: var(--page-bg);
  color: var(--text-color);
}

#root {
  min-height: 100vh;
}

.App {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

h1 {
  margin: 0;
  text-align: left;
}
h2 {
  margin: 20px 0;
  text-align: left;
}
h3 {
  margin: 10px 0;
  text-align: left;
  font: size 1.5rem;
}
p {
  font-size: 20px;
}
a {
  text-decoration: none;
  color: #7bc0e3;
}

form {
  /* position: absolute;
  display: none;
  width: 450px;
  height: 450px;
  left:500px;
  top: 50px;
 */
  text-align: center;
}

input {
  width: 90%;
  height: 65px;
  margin: 20px 0 0 0;
  padding: 15px;
  color: #212a71;
  background: #ffffff;
  font-size: 19px;

  border: 1px solid #212a71;
  box-sizing: border-box;
  border-radius: 10px;
}

.img-rotate{
  position: absolute;
}
.logos{
  align-items: center;
text-align: center;
}
section {
  margin-top: 0px;
}
.container {
  box-sizing: border-box;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content:space-around;
  align-items: left;
  max-width: 1200px;
  width: 100%;
  margin-top: 0;
  margin: auto;
  padding: 0;
}
.column {
  display: flex;
  flex-direction: column;
}
.row {
  display: flex;
  flex-direction: row;
}
ul.logos {
  box-sizing: border-box;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-between;
  /* padding: 0; */
  align-items: center;
  min-width: 1200px;
  margin: 0;
}
ul.logos li {
  /* display: inline-block; */
  vertical-align: middle;
  list-style-type: none;
  text-align: justify;
  color: #7bc0e3;
}
.header {
  display: none;
}

.logo {
  margin-top: 20px;
  text-align: center;
  font-size: 30px;
  color: #212a71;
}
.minilogo {
  max-width: 30%;
  margin: 20px auto;
}
.btn {
  font-size: 20px;
  padding: 12px 25px;
  margin: 3px;
  color: white;
  background: #7bc0e3;
  border-radius: 1000px;
  border: none;
}

.btn-disabled {
  font-size: 20px;
  padding: 12px 25px;
  margin: 3px;
  color: #7bc0e3;
  border: 1px solid #7bc0e3;
  background-color: white;
  border-radius: 1000px;
}
.btn-black {
  font-size: 20px;
  padding: 10px 15px;
  margin: 3px;
  color: white;
  border: 1px solid white;
  background-color: black;
  border-radius: 1000px;
}
.container-offer {
  box-sizing: border-box;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
}
.business-offer {
  box-sizing: border-box;
  position: relative;
  display: flex;
  flex-wrap: wrap;
    flex-direction: row;
      justify-content: left;
  margin: auto;
  margin-left: 0;
  /* max-width: 450px; */
}
.circles {
  position: absolute;
  /* display: block; */
  max-width: 300px;
  margin-top: -50px;
  width: 300px;
  height: 300px;
  z-index: -1;
}
.analyse {
  max-width: 500px;

  box-sizing: border-box;
  position: relative;
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
}
.error {
  color: red;
  font-size: 14px;
}
.slider2 {
  margin-bottom: 20px;
}
.our-services {
  display: flex;
  flex-direction: column;
  background-color: #e9fbf8;
}
.box-types {
  box-sizing: border-box;

  max-width: 1200px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}

/* .types {
  box-sizing: border-box;
  width: 200px;
  height: 250px;
  display: flex;
  flex-direction: column;
  justify-content: left;
  align-items: center;
  min-width: 363px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 30px;
  padding: 15px;
  border-top: 7px solid #60e1cb;
  border-radius: 10px;
  background-color: white;
  box-shadow: 0px 4px 4px 2px rgba(0, 0, 0, 0.1);
} */
.card {
  box-sizing: border-box;
  margin-bottom: 30px;
  padding: 15px;
  border-top: 7px solid #60e1cb;
  border-radius: 10px;
  box-shadow: 0px 4px 4px 2px rgba(0, 0, 0, 0.1);
}
.web-site {
  width: 45px;
  height: 45px;
  text-align: left;
  background: url(./img/web-site.svg);
}
.web-design {
  width: 45px;
  height: 45px;
  text-align: left;
  background: url(./img/web-design.svg);
}
.corp-site {
  width: 45px;
  height: 45px;
  text-align: left;
  background: url(./img/layers.svg);
}
.redesign {
  width: 45px;
  height: 45px;
  text-align: left;
  background: url(./img/web-design.svg);
}
.services {
  width: 45px;
  height: 45px;
  text-align: left;
  background: url(./img/magnifying-glass.svg);
}
.promo-site {
  width: 45px;
  height: 45px;
  text-align: left;
  background: url(./img/startup.svg);
}
.our-work {
  background: linear-gradient(to bottom, white 60%, #60e1cb 40%);
}
.container-work {
  box-sizing: border-box;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: space-between;
  align-items: left;
  max-width: 1200px;
  margin-top: 0;
  margin: auto;
  padding: 0;
}

slider {
  margin-top: 15px;
  margin-bottom: 15px;
  box-sizing: border-box;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  vertical-align: middle;
  text-align: center;
}
.img1,
.img2,
.img3 {
  display: flex;
  width: 400px;
  /* height: 150px; */
  padding: 10px;
}
.imgs {
  display: flex;
  flex-wrap: nowrap;
  flex-flow: row;
}
.svg {
  display: flex;
}
.btn-prev {
  position: absolute;
  margin: 7% -2%;
  z-index: 1000;
}

.btn-prev::before,
.btn-prev2::before {
  padding: 20px 30px;
  content: "";
 background: #FFFFFF;
box-shadow: 0px 2px 5px 0 rgba(59, 59, 59, 0.32);
 border: 1px solid white;
  border-radius:100%;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url(./img/prev.svg);
}
.btn-next {
  position: absolute;
  margin: 7% 97%;
}

.btn-next::before,
.btn-next2::before {
  padding: 20px 30px;
  content: "";
 background: #FFFFFF;
box-shadow: 0px 2px 5px 0 rgba(59, 59, 59, 0.32);
 border: 1px solid white;
  border-radius:100%;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url(./img/next.svg);
}

.container-creative {
  box-sizing: border-box;
  position: relative;
  display: flex;

  flex-direction: column;
  justify-content: space-between;
  align-items: left;
  max-width: 1200px;
  margin-top: 0;
  margin: auto;
  padding: 0;
}
.response {
  font-size: medium;
  width: 70%;
}
.btn-prev2 {
  position: absolute;
  margin: 20% 2%;
  z-index: 1000;
}
.btn-next2 {
  position: absolute;
  margin: 20% 76%;
}

.comment {
  display: none;
}
.comment.active {
  display: flex;
  padding-left: 20%;
}

.look {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: -10px;
}
.tasks {
  box-sizing: border-box;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  /* flex-direction: column; */
  justify-content: left;
  max-width: 1200px;
  margin-left: 0;
  padding: 0;
}
ul.tasks {
  box-sizing: border-box;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  /* flex-direction: column; */
  justify-content: left;
  max-width: 1200px;
  /* margin-left: 0;
padding: 0;     */
}
ul.tasks li {
  /* display: inline-block; */
  vertical-align: middle;
  list-style-type: none;
  text-align: justify;
  color: #7bc0e3;
}
.number {
  display: inline-block;
  vertical-align: top;
  width: 200px;
  margin: auto;
}
.number2 {
  display: inline-block;
  vertical-align: top;
  width: 400px;
  color: #212a71;
  margin: auto;
}

.idea {
  display: none;
  max-width: 1200px;
  margin: auto;
  background-image: url(./img/WorkingRemotely.png);
  background-repeat: no-repeat;
  background-position-x: right;
  background-position-y: bottom;
}
.work {
  min-height: 250px;
  background: url(./img/MaskGroup.png) repeat;
  background-color: #60e1cb;
}

.our-blog {
  background-color: #e9fbf8;
  min-height: 250px;
}

footer {
  min-height: 250px;
  background: url(./img/MaskGroup.png) repeat;
  background-color: #60e1cb;
}

.carousel-indicators{
  display:flex;
  flex-direction: column;
  align-items: center;
  font-size:28px;
}
.carousel-item{
  display: flex;
  flex-direction: column;
  align-items: center;
}
.image-w-90 {
  width: 50%;
}

.buttons {
  margin-top: 20px;
}
.btns {
  position: relative;
  box-sizing: border-box;
  display: inline-block;

  font-size: 15px;
  font-weight: bold;
  color: #ffffff;
  text-align: center;

  background-color: #ffffff;
  border-radius: 10px;

  transition: all 0.25s;
}

.btns::before {
  position: absolute;

  width: 30px;
  height: 30px;
  content: "";

  background-repeat: no-repeat;
  background-position: center;
}
.btn-whatsapp {
  position: absolute;
  left: 0px;
}

.btn-telegram {
  position: absolute;
  left: 80px;

}.btn-vk {
  position: absolute;
  left: 160px;
}

.btn-qr {
  position: absolute;
  left: 220px;
}
.btn-more {
  position: absolute;
  left: 400px;
}
.btn-whatsapp::before {
  padding-right: 0px;
  background-image: url(./img/icons/whatsapp.png);
}
.btn-telegram::before {
  background-image: url(./img/icons/telegram.png);
}
.btn-vk::before {
  background-image: url(./img/icons/icon-vk.svg);
}
.btn-qr::before {
  margin: 0 0 0 20px;
  padding: 0 60px;
  background-image: url(./img/icons/icon-qr.svg);
}
.btn-more::before {
  background-image: url(./img/icons/more.svg);
}
.container-info {
  box-sizing: border-box;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: space-between;
  align-items: left;
  max-width: 1200px;
  margin: auto;
  margin-top: 50px;
  padding: 0;
}
.container-info2 {
  display: none;
}

/* ProjectTwo */

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;700&display=swap');

/* * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: Inter, sans-serif;
}

body {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    background: #f5f5f5;
}
 */
.wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  padding: 20px;
  display: flex;
  align-content: center;
  justify-content: center;
  gap: 24px;
  flex-wrap: wrap;
}

.wrapper3 {
  position: relative;
  width: 80%;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.card2 {
    position: relative;
    width: 490px;
    height: 450px;
    background: #000;
    border-radius: 18px;
    overflow: hidden;
    box-shadow: 0 5px 10px rgba(0, 0, 0, .2);
}
.card3 {
    width: 1024px;
    text-align: center;
}

.poster {
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.poster::before {
    content: '';
    position: absolute;
    bottom: -45%;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    transition: .3s;
}

.card2:hover .poster::before {
    bottom: 0;
}

.poster img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: .3s;
}

.card2:hover .poster img {
    transform: scale(1.1);
}

.details {
    position: absolute;
    bottom: -100%;
    left: 0;
    width: 100%;
    height: auto;
    padding: 1.5em 1.5em 2em;
    background: #000a;
    backdrop-filter: blur(16px) saturate(120%);
    transition: .3s;
    color: #fff;
    z-index: 2;
}

.card2:hover .details {
    bottom: 0;
}

.details h1,
.details h2 {
    font-weight: 700;
}

.details h1 {
    font-size: 1.5em;
    margin-bottom: 5px;
}

.details h2 {
    font-weight: 400;
    font-size: 1em;
    margin-bottom: 10px;
    opacity: .6;
}

.details .rating {
    position: relative;
    margin-bottom: 15px;
    display: flex;
    gap: .25em;
}

.details .rating i {
    color: #e3c414;
}

.details .rating span {
    margin-left: 0.25em;
}

.details .tags {
    display: flex;
    gap: .375em;
    margin-bottom: .875em;
    font-size: .85em;
}

.details .tags span {
    padding: .35rem .65rem;
    color: #fff;
    border: 1.5px solid rgba(255 255 255 / 0.4);
    border-radius: 4px;
    border-radius: 50px;
}

.details .desc {
    color: #fff;
    opacity: .8;
    line-height: 1.5;
    margin-bottom: 1em;
}

.details .cast h3 {
    margin-bottom: .5em;
}

.details .cast ul {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    gap: 0.625rem;
    width: 100%;
}

.details .cast ul li {
    list-style: none;
    width: 55px;
    height: 55px;
    border-radius: 50%;
    overflow: hidden;
    border: 1.5px solid #fff;
}

.details .cast ul li img {
    width: 100%;
    height: 100%;
}

/* end of ProjectTwo */

@media (min-width: 1001px) and (max-width: 1200px) {
  .container,
  .container-offer {
    max-width: 1000px;
  }
  ul.logos {
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-between;
      min-width: 300px;
  }
  .menu {
    display: none;
  }

  .btn {
    font-size: 18px;
    padding: 10px 15px;
    margin: 3px;
  }

  .btn-disabled {
    font-size: 18px;
    padding: 10px 15px;
    margin: 3px;
  }
  .circles {
    position: absolute;
    /* display: block; */
    max-width: 300px;
    margin-top: -50px;
    width: 300px;
    height: 300px;
    z-index: -1;
  }

  .analyse {
    max-width: 600px;
  }
  .types {
    min-width: 300px;
  }
  .tasks {
    justify-content: left;
    max-width: 1000px;
    margin-left: 0;
    padding: 0;
  }
  ul.tasks {
    justify-content: left;
    max-width: 1000px;
  }
  ul.tasks li {
    /* display: inline-block; */
    vertical-align: middle;
    list-style-type: none;
    text-align: justify;
  }
  .number {
    display: inline-block;
    vertical-align: top;
    width: 200px;
    margin: auto;
  }
  .number2 {
    display: inline-block;
    vertical-align: top;
    width: 400px;
    color: #212a71;
    margin: auto;
  }

  .idea {
    display: none;
    background-image: url(./img/WorkingRemotely.png);
    background-size: contain;
    background-repeat: no-repeat;
    background-position-x: right;
    background-position-y: bottom;
  }
.card3 {
  width: 1024px;
  text-align: center;
}
  slider {
    margin-top: 15px;
    margin-bottom: 15px;
    box-sizing: border-box;
    position: relative;
    display: flex;
    flex-wrap: wrap;
    vertical-align: middle;
  }
  .img1,
  .img2,
  .img3 {
    display: flex;
    width: 350px;
    /* height: 150px; */
    padding: 10px;
  }
  .btn-prev {
    position: absolute;
    margin: 7% -2%;
  }

    .btn-next {
    position: absolute;
    margin: 7% 97%;
  }

 }

@media (min-width: 601px) and (max-width: 1000px) {
  .container,
  .container-offer {
    text-align: left;
    max-width: 600px;
  }

  .container-work{
    max-width: 600px;
  }
  .idea {
    display: none;
    background: none;
  }
    .column {
      display: flex;
      flex-direction: column;
    }
  
    .row {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content:center;
      align-self:center;
    }
  ul.logos {
    display: none;
  }
  .nav-mobile {
    /* max-width: 900px; */
    /* margin-top: -30px; */
    /* position: relative; */
    background: #fff;
    overflow: inherit;
  }
  .header {
    height: 70px;
    position: relative;
    display: block;
  }
  .menu-triger {
    background: url(./img/menu.png) 0 0 repeat;
    display: block;
    width: 40px;
    height: 40px;
    position: relative;
    right: 0%;
      top: -10px;  }
  .menu-close {
    background: url(./img/close.png) 0 0 repeat;
    display: block;
    width: 40px;
    height: 40px;
    position: absolute;
    right: 30px;
    top: 10px;
    z-index: 100;
  }
  .menu-popup {
    background: white;
    display: none;
    position: absolute;
    right: -110%;
    top: 0;
    width: 250px;
    box-sizing: border-box;
    padding: 30px;
    z-index: 9999;
    box-shadow: 10px 14px 28px #212a71, 10px 10px 10px #212a71;
  }
  .menu-popup ul {
    margin: 0;
    padding: 0;
  }
  .menu-popup li {
    list-style: none;
    margin: 10px 0;
    padding: 0;
  }
  .menu-popup li a {
    font-size: 18px;
    font-family: "Arial";
    line-height: 1.4;
    text-decoration: none;
  }
  .body_pointer * {
    cursor: pointer;
  }
.image-w-90 {
  width: 60%;
}
  .btn {
    font-size: 18px;
    padding: 10px 15px;
    margin: 3px;
  }

  .btn-disabled {
    font-size: 18px;
    padding: 10px 15px;
    margin: 3px;
  }

  .analyse {
    max-width: 400px;
  }
  .circles {
    position: absolute;
    /* display: block; */
    max-width: 300px;
    margin-top: -50px;
    width: 300px;
    height: 300px;
    z-index: -1;
  }

  .types {
    min-width: 300px;
  }

  .tasks {
    box-sizing: border-box;
    position: relative;
    display: flex;
    flex-wrap: wrap;
    /* flex-direction: column; */
    justify-content: left;
    max-width: 600px;
    margin-left: 0;
    padding: 0;
  }
  ul.tasks {
    box-sizing: border-box;
    position: relative;
    display: flex;
    flex-wrap: wrap;
    /* flex-direction: column; */
    justify-content: left;
    max-width: 600px;
    /* margin-left: 0;
padding: 0;     */
  }
  ul.tasks li {
    /* display: inline-block; */
    vertical-align: middle;
    list-style-type: none;
    text-align: justify;
    color: #7bc0e3;
  }
  .number {
    display: inline-block;
    vertical-align: top;
    width: 100px;
    margin: auto;
  }
  .number2 {
    display: inline-block;
    vertical-align: top;
    width: 300px;
    color: #212a71;
    margin: auto;
  }

  .idea {
    display: none;
    background-image: url(./img/WorkingRemotely.png);
    background-size: contain;
    background-repeat: no-repeat;
    background-position-x: right;
    background-position-y: bottom;
  }
.card3 {
  width: 720px;
  text-align: center;
}
  slider {
    margin-top: 15px;
    margin-bottom: 15px;
    box-sizing: border-box;
    position: relative;
    display: flex;
    flex-wrap: wrap;
    vertical-align: middle;
  }
  .img1,
  .img2,
  .img3 {
    display: flex;
    width: 220px;
    /* height: 150px; */
    padding: 10px;
  }
  .btn-prev {
    position: absolute;
    margin: 7% -2%;
  }

    .btn-next {
    position: absolute;
    margin: 7% 97%;
  }

 }

@media (min-width: 320px) and (max-width: 600px) {
  h2 {
    text-align: center;
  }
  h3{
    font-size: 15px;
  }
  p {
    text-align: justify;
    padding: 10px;
  }
  .container,
  .container-offer {
    max-width: 300px;
    font-size: 15px;
  }
  .business-offer {
    flex-direction: column;
    justify-content: center;
  }
  .idea {
    display: none;

    background: none;
  }
    .column {
      display: flex;
      flex-direction: row;
      flex-wrap: no-wrap;
    }
  
    .row {
      display: flex;
      flex-direction: column;
    }
  ul.logos {
    display: none;
  }
  .nav-mobile {
    /* width: 200px; */
    /* margin-top: -30px; */
    /* position: relative; */
    background: #fff;
    overflow: inherit;
  }
  .header {
    height: 70px;
    position: relative;
    display: block;
  }
  .menu-triger {
    background: url(./img/menu.png) 0 0 repeat;
    display: block;
    width: 40px;
    height: 40px;
    position: relative;
    right: 0%;
    top: -10px;
  }
  .menu-close {
    background: url(./img/close.png) 0 0 repeat;
    display: block;
    width: 40px;
    height: 40px;
    position: absolute;
    right: 30px;
    top: 10px;
    z-index: 100;
  }
  .menu-popup {
    background: white;
    display: none;
    position: absolute;
    right: -110%;
    top: 0;
    width: 250px;
    box-sizing: border-box;
    padding: 30px;
    z-index: 9999;
    box-shadow: 0 14px 28px #212a71, 0 10px 10px #212a71;
  }
  .menu-popup ul {
    margin: 0;
    padding: 0;
  }
  .menu-popup li {
    list-style: none;
    margin: 10px 0;
    padding: 0;
  }
  .menu-popup li a {
    font-size: 18px;
    font-family: "Arial";
    line-height: 1.4;
    text-decoration: none;
  }
  .body_pointer * {
    cursor: pointer;
  }
    .image-w-90 {
      width: 90%;
    }
  .btn {
    font-size: 14px;
    padding: 10px 15px;
    margin: 3px;
  }

  .btn-disabled {
    font-size: 14px;
    padding: 10px 15px;
    margin: 3px;
  }
  .circles {
    position: absolute;
    /* display: block; */
    max-width: 100px;
    margin-top: -30px;
    width: 100px;
    height: 100px;
    z-index: -1;
  }

  .analyse {
    max-width: 200px;
  }
  .types {
    min-width: 250px;
    min-height: 300px;
  }

  .tasks {
    box-sizing: border-box;
    position: relative;
    display: flex;
    flex-wrap: wrap;
    /* flex-direction: column; */
    justify-content: left;
    max-width: 300px;
    margin: auto;
    padding: 0;
  }
  ul.tasks {
    box-sizing: border-box;
    position: relative;
    display: flex;
    flex-wrap: wrap;
    /* flex-direction: column; */
    justify-content: left;
    max-width: 300px;
    /* margin-left: 0;
padding: 0;     */
  }
  ul.tasks li {
    /* display: inline-block; */
    vertical-align: middle;
    list-style-type: none;
    text-align: justify;
    color: #7bc0e3;
  }
  .number {
    display: inline-block;
    vertical-align: top;
    width: 100px;
    margin: auto;
  }
  .number2 {
    display: inline-block;
    vertical-align: top;
    width: 300px;
    color: #212a71;
    margin: auto;
  }

  .idea {
    display: none;

    background-image: none;
  }
  .container-work {
    max-width: 300px;
  }
  slider {
    margin-top: 15px;
    margin-bottom: 15px;
    box-sizing: border-box;
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    vertical-align: middle;
  }
  .img1 {
    width: 300px;
    /* height: 75px; */
    padding: 5px;
  }
  .img2,
  .img3 {
    width: 0;
    /* height: 0px; */
    padding: 0;
  }

  .btn-prev {
    position: absolute;
    margin: 7% -9%;
  }

   .btn-next {
    position: absolute;
    margin: 7% 86%;
  }

 
  .btn-whatsapp {
  position: absolute;
  left: 0px;
}

.btn-telegram {
  position: absolute;
  left: 80px;

}.btn-vk {
  position: absolute;
  left: 160px;
}
.btn-qr {
  display: none;
  position: absolute;
  left: 220px;
}
.btn-more {
  display: none;
  position: absolute;
  left: 400px;
}

  .btn-vk::before {
    /* padding-right: 0px; */
    background-image: url(./img/icons/icon-vk.svg);
  }
  .btn-fb::before {
    background-image: url(./img/icons/icon-fb.svg);
  }
  .btn-google::before {
    background-image: url(./img/icons/icon-google.svg);
  }
  .container-info2 {
    box-sizing: border-box;
    position: relative;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: space-between;
    align-items: left;
    max-width: 200px;
    margin: auto;
    margin-top: 20px;
    padding: 0;
  }
  .container-info {
    display: none !important;
  }


  .card2 {
    position: relative;
    width: 270px;
    height: 450px;
    background: #000;
    border-radius: 18px;
    overflow: hidden;
    box-shadow: 0 5px 10px rgba(0, 0, 0, .2);
}
.card3 {
  width: 270px;
  max-width: 80%;
  text-align: center;
}
.details h1 {
    font-size: 1.0em;
    margin-bottom: 5px;
}

.cast{
    font-size: 0.6em !important;
}

}
